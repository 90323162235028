@tailwind base;
@tailwind components;
@tailwind utilities;

$primaryColor: #166079;

input[type='file'] {
  opacity: 0; /* make transparent */
}

::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  -webkit-background-clip: content-box;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
.ms-Button--primary {
  // This line is required to prevent tailwind from overriding the fluent ui button
  background-color: $primaryColor !important;
}
